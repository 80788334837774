<template>
  <div class="tile">
    <span class="icon-container">
      <slot name="icon"></slot>
    </span>
    <div class="value-container">
      <span class="title">{{ title }}</span>
      <span class="value">{{ value }}</span>
    </div>
    <slot name="button"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MSnapshotItemCountTile",
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.tile {
  max-width: 260px;
  height: 72px;
  flex-grow: 0;
  padding: 8px 24px 8px 16px;
  border: solid 1px rgba(16, 185, 129, 0.2);
  background-color: rgba(16, 185, 129, 0.05);

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  overflow-wrap: break-word;

  @media (max-width: 767px) {
    max-width: 280px;
  }

  .value-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    overflow: hidden;

    .value {
      font-size: 14px;
    }

    .title {
      font-weight: bold;
      font-size: 13px;
    }
  }
}

.icon-container {
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  background-color: var(--green-color);
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  border-radius: 50px;
}

.icon-container > svg {
  color: #fff !important;
  fill: #fff !important;
}
</style>
