<template>
  <div class="btn-container" v-if="showAddNewAgentBtn()">
    <MButton
      variant="filled"
      @click="onAddNewAgentBtnClick"
      v-if="hasAddNewAgent"
      >Add New Agent</MButton
    >
  </div>
  <MDataTable
    :has-pagination="hasPagination"
    :has-search="hasSearch"
    :headers="headers"
    :data="agents"
  >
    <template #type="{ item }">
      <div class="chip-cell-container">
        <MChip
          class="type-chip"
          :border-less="true"
          :variant="renderTypeVariant(item?.type) || 'default'"
        >
          {{ item.type }}
        </MChip>
      </div>
    </template>

    <template #can_login="{ item }">
      <div class="icon-cell-container">
        <RoundCheckCrossIcon :is-check="item.can_login" />
      </div>
    </template>

    <template #can_issue_flight="{ item }">
      <div class="icon-cell-container">
        <RoundCheckCrossIcon :is-check="item.can_issue_flight" />
      </div>
    </template>

    <template #can_issue_hotel="{ item }">
      <div class="icon-cell-container">
        <RoundCheckCrossIcon :is-check="item.can_issue_hotel" />
      </div>
    </template>

    <template #action="{ item }">
      <div class="delete-btn-container">
        <MFabButton
          v-if="isEnableUserDeletion(item?.type)"
          @click="onDeleteAgentRequest(item?.agent)"
          class="delete-icon"
          icon="m-delete"
        />
        <MFabButton
          v-else
          class="delete-icon"
          :disabled="true"
          icon="m-delete"
        />
      </div>
    </template>
  </MDataTable>
  <AddAgentModal
    :is-open="isAddAgentDialogOpen"
    @handleClose="onCloseAddAgentDialog"
    @onSubmit="onAddEmail"
  />
  <AgentDeleteConfirmationModal
    :isOpen="isDeleteAgentDialogOpen"
    :agent="selectedAgent"
    @onCloseModal="closeAgentDeletionModal"
    @onDeleteAgent="deleteAgent"
  />
</template>

<script lang="ts">
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import { PERMISSIONS } from "@/ag-portal-common/enums/PERMISSIONS";
import { IAgentV2 } from "@/ag-portal-common/interfaces/agent.interface";
import { defineComponent } from "vue";
import { MDataTable, MChip, MButton, MFabButton } from "@aeroglobe/ag-core-ui";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import RoundCheckCrossIcon from "./CheckCrossIconComponent.vue";
import AddAgentModal from "../OrganizationModals/AddAgentModal.vue";
import { AddAgentDto } from "@/modules/Organization/dtos/agent.dto";
import AgentDeleteConfirmationModal from "@/modules/Agent/components/AgentDeleteConfirmationModal.vue";
import analyticsService from "@/analytic.service";
import { AGENT_ANALYTICS_EVENTS } from "@/modules/Agent/constants/analyticsEvents";
import { MDataTableHeader } from "@/ag-portal-common/types";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "AgentTable",
  components: {
    MButton,
    MDataTable,
    MChip,
    RoundCheckCrossIcon,
    AddAgentModal,
    AgentDeleteConfirmationModal,
    MFabButton,
  },
  props: {
    hasPagination: {
      type: Boolean,
      default: true,
    },
    hasSearch: {
      type: Boolean,
      default: true,
    },
    searchedEmail: {
      type: String,
      default: "",
    },
    hasAddNewAgent: {
      type: Boolean,
      default: false,
    },
    organizationId: {
      type: String,
      default: "",
    },
  },
  data(): {
    headers: MDataTableHeader[];
    searchedAgentEmail: string;
    isAddAgentDialogOpen: boolean;
    isDeleteAgentDialogOpen: boolean;
    selectedAgent: IAgentV2 | null;
  } {
    return {
      headers: [
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
        },
        {
          title: "Type",
          value: "type",
          key: "type",
          sortable: true,
        },
        {
          title: "Can Login",
          value: "can_login",
          key: "can_login",
          sortable: true,
        },
        {
          title: "Can Issue Flight Ticket",
          value: "can_issue_flight",
          key: "can_issue_flight",
          sortable: true,
        },
        {
          title: "Can Issue Hotel",
          value: "can_issue_hotel",
          key: "can_issue_hotel",
          sortable: true,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: true,
        },
      ],
      searchedAgentEmail: "",
      isAddAgentDialogOpen: false,
      isDeleteAgentDialogOpen: false,
      selectedAgent: null,
    };
  },
  methods: {
    renderTypeVariant(type: string | undefined) {
      switch (type) {
        case AGENT_SUB_ROLES.EMPLOYEE:
          return "success";
        case AGENT_SUB_ROLES.OWNER:
          return "info";
        case AGENT_SUB_ROLES.SUB_AGENT:
          return "warning";
        default:
          return "";
      }
    },
    onAddNewAgentBtnClick() {
      this.isAddAgentDialogOpen = true;
    },
    isEnableUserDeletion(agentSubRole: AGENT_SUB_ROLES) {
      if (this.isSuperUser && agentSubRole === AGENT_SUB_ROLES.OWNER) {
        return true;
      }
      if (this.isUserAgent && agentSubRole !== AGENT_SUB_ROLES.OWNER) {
        return true;
      }
      return false;
    },
    onAddEmail(payload: AddAgentDto) {
      this.$store.dispatch("addAgentInOrganization", {
        body: payload,
        orgId: this.organizationId,
        callback: this.onCloseAddAgentDialog,
      });

      const analyticsPayload = {
        "organisation-id": this.organizationId,
        "user-email": payload.email,
        "user-fullname": payload.full_name,
        "user-role": payload.role,
      };

      analyticsService.logActionEvent(
        AGENT_ANALYTICS_EVENTS.AGENT_ADD,
        analyticsPayload
      );
    },
    onCloseAddAgentDialog() {
      this.isAddAgentDialogOpen = false;
    },
    deleteAgent() {
      this.$store.dispatch("deleteAgentFromOrganization", {
        agentId: this.selectedAgent?.agent_id,
        callback: this.agentDeleteSuccessCallback,
      });
    },
    agentDeleteSuccessCallback() {
      const payload = {
        "agent-id": this.selectedAgent?.agent_id,
        "agent-traacs-code": this.selectedAgent?.agent_traacs_code,
        "agent-user-role": this.selectedAgent?.user.role_unique_id,
        "agent-user-type": this.selectedAgent?.user.role_sub_type,
        "agent-user-email": this.selectedAgent?.user.email,
        "agent-user-permissions":
          this.selectedAgent?.user.permissions.join(", "),
        "agent-user-fullname": this.selectedAgent?.user.full_name,
      };

      analyticsService.logActionEvent(
        AGENT_ANALYTICS_EVENTS.AGENT_DELETE,
        payload
      );

      this.closeAgentDeletionModal();
    },
    onDeleteAgentRequest(agent: IAgentV2) {
      this.selectedAgent = agent;
      this.isDeleteAgentDialogOpen = true;
    },
    closeAgentDeletionModal() {
      this.isDeleteAgentDialogOpen = false;
    },
    showAddNewAgentBtn() {
      return this.isUserAgent || this.isSuperUser;
    },
  },
  computed: {
    agents(): {
      email: string;
      type: AGENT_SUB_ROLES;
      can_login: boolean;
      can_issue_flight: boolean;
      can_issue_hotel: boolean;
      agent: IAgentV2;
      action: string;
    }[] {
      const agents =
        (this.$store.getters.organization?.agents as IAgentV2[]) || [];
      const can_login = PERMISSIONS.CAN_LOGIN;
      const can_issue_flight = PERMISSIONS.CAN_ISSUE_FLIGHT_TICKET;
      const can_issue_hotel = PERMISSIONS.CAN_ISSUE_HOTEL;

      const mappedAgents =
        agents?.map((item: IAgentV2) => {
          return {
            email: item?.user?.email,
            type: item?.user?.role_sub_type,
            can_login: item?.user.permissions.includes(can_login),
            can_issue_flight: item?.user.permissions.includes(can_issue_flight),
            can_issue_hotel: item?.user.permissions.includes(can_issue_hotel),
            agent: item,
            action: "delete",
          };
        }) || [];

      if (this.searchedEmail) {
        const filteredAgent = mappedAgents.filter(
          (item) => item.email === this.searchedEmail
        );
        return filteredAgent;
      } else {
        return mappedAgents;
      }
    },

    isUserAgent() {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
    isSuperUser() {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
  },
});
</script>

<style scoped lang="scss">
table,
th,
tr,
td {
  font-family: Poppins;
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 10px 20px;
  }
}

.icon-m-cross {
  background: rgba(230, 90, 90, 0.1);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  .icon {
    color: #e65a5a;
    display: flex;
    width: 20px;
    height: 20px;
    svg {
      width: 18px;
    }
  }
}

.icon-m-check {
  background: rgba(16, 176, 18, 0.1);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  .icon {
    color: #10b949;
    display: flex;
    width: 20px;
    height: 20px;
    svg {
      width: 18px;
    }
  }
}
.delete-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-icon {
  color: #e65a5a !important;
}

.icon-cell-container {
  display: flex;
  justify-content: center;
}
.chip-cell-container {
  display: flex;
  justify-content: center;
}
.type-chip {
  font-weight: 600;
}
</style>
