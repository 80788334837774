import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5319c3c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "btn-container" }
const _hoisted_3 = { class: "table-column-width pricing-chip-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_MFinancialProfileCombobox = _resolveComponent("MFinancialProfileCombobox")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_RoundCheckCrossIcon = _resolveComponent("RoundCheckCrossIcon")!
  const _component_MDataTable = _resolveComponent("MDataTable")!

  return (_ctx.isFinancialProfileFetching)
    ? (_openBlock(), _createBlock(_component_MProgress, {
        key: 0,
        indeterminate: "",
        class: "mprogress-alignment"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MCard, { class: "search-bar-card" }, {
          default: _withCtx(() => [
            _createVNode(_component_AgRow, null, {
              default: _withCtx(() => [
                _createVNode(_component_AgColumn, {
                  sm: "12",
                  md: "8",
                  lg: "8",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MFinancialProfileCombobox, {
                      class: "fp-combo",
                      inputValue: _ctx.financialProfileInput,
                      "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.financialProfileInput) = $event)),
                      label: "Financial Profiles",
                      itemValue: "value",
                      itemLabel: "label",
                      disabled: _ctx.isFinancialProfileFetching,
                      options: _ctx.financialProfileOptions,
                      hasError: _ctx.hasError,
                      errorMessage: _ctx.errorMessage
                    }, null, 8, ["inputValue", "disabled", "options", "hasError", "errorMessage"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgColumn, {
                  sm: "12",
                  md: "4",
                  lg: "4",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_MButton, {
                        onClick: _ctx.onFetchAgPricing,
                        class: "get-price-detail-btn",
                        disabled: _ctx.getPriceDetailsButtonDisabled
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Get Pricing ")
                        ]),
                        _: 1
                      }, 8, ["onClick", "disabled"]),
                      _createVNode(_component_MButton, {
                        type: "outlined",
                        onClick: _ctx.handleClear
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Clear ")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.showTable)
          ? (_openBlock(), _createBlock(_component_MDataTable, {
              key: 0,
              headers: _ctx.tableHeaders,
              "is-loading": _ctx.isLoading,
              data: _ctx.pricings || [],
              "item-per-page": 100,
              "has-search": true
            }, {
              sales_channel: _withCtx(({ item }) => [
                _createElementVNode("div", null, [
                  _createVNode(_component_MTypography, {
                    class: "description td-full-width",
                    type: "body"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.sales_channel), 1)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]),
              airline: _withCtx(({ item }) => [
                _createVNode(_component_MTypography, {
                  class: "description td-full-width",
                  type: "label"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.airline), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              pricing: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_MChip, {
                    "border-less": true,
                    class: "pricing-chip",
                    variant: _ctx.renderChipVariant(item.pricing)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.pricing), 1)
                    ]),
                    _: 2
                  }, 1032, ["variant"])
                ])
              ]),
              apply_on_gross_fare: _withCtx(({ item }) => [
                _createVNode(_component_RoundCheckCrossIcon, {
                  "is-check": item.apply_on_gross_fare
                }, null, 8, ["is-check"])
              ]),
              sale_on_and_after: _withCtx(({ item }) => [
                _createVNode(_component_MTypography, {
                  class: "description td-full-width",
                  type: "label"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getFormattedDates(item.sale_on_and_after)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              sale_on_and_before: _withCtx(({ item }) => [
                _createVNode(_component_MTypography, {
                  class: "description td-full-width",
                  type: "label"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getFormattedDates(item.sale_on_and_before)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              travel_on_and_after: _withCtx(({ item }) => [
                _createVNode(_component_MTypography, {
                  class: "description td-full-width",
                  type: "label"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getFormattedDates(item.travel_on_and_after)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              travel_on_and_before: _withCtx(({ item }) => [
                _createVNode(_component_MTypography, {
                  class: "description td-full-width",
                  type: "label"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getFormattedDates(item.travel_on_and_before)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              remarks: _withCtx(({ item }) => [
                _createVNode(_component_MTypography, {
                  class: "description td-full-width",
                  type: "label"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getRemarks(item.remarks)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              rbds: _withCtx(({ item }) => [
                _createVNode(_component_MTypography, {
                  class: "description td-full-width",
                  type: "label"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.displayRbds(item.rbds)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["headers", "is-loading", "data"]))
          : _createCommentVNode("", true)
      ]))
}