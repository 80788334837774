import download from "downloadjs";
import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { IMyBookingsFlight } from "@/interfaces/flightBookings.interface";
import { IMyBookingsTour } from "@/interfaces/tourBookings.interface";
import { MyBookingsMapper } from "@/modules/MyBookings/responseMappers/myBookings.mapper";
import { MyBookingsResponseModel } from "@/modules/MyBookings/models/myBookings.response";
import { MY_BOOKINGS_API_PATH } from "@/modules/MyBookings/constants";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import { formatString } from "@/ag-portal-common/utils/helpers";
import { SendAirlineTicketBodyRequest } from "@/modules/MyBookings/models/sendAirlineTicketBody.request";
import { IMyBookingsHotel } from "@/interfaces/hotelBookings.interface";
import { CACHE_NAMES } from "@/ag-portal-common/constants";

class MyBookingsService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public async fetchIssuanceLogs(pnr: string): Promise<any> {
    const methodName = "MyBookingsService.fetchPaymentLogs";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      // You can directly call the RestClientService get method here without an API path.
      const response: IAGResponse = await this.restClient.get(
        MY_BOOKINGS_API_PATH.ISSUEANCE_LOGS + pnr + "/"
      );

      if (response.data && response.success) {
        return response.data;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
      // Handle errors if necessary.
      throw err;
    }
  }

  public async fetchBookingLogs(id: string): Promise<any> {
    const methodName = "MyBookingsService.fetchBookingLogs";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      // You can directly call the RestClientService get method here without an API path.
      const response: IAGResponse = await this.restClient.get(
        MY_BOOKINGS_API_PATH.BOOKING_LOGS + "/" + id
      );

      if (response.data && response.success) {
        return response.data;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
      // Handle errors if necessary.
      throw err;
    }
  }
  public downloadVoucher(bookingId: string): Promise<IAGResponse> {
    return this.restClient.get(
      `${MY_BOOKINGS_API_PATH.DOWNLOAD_VOUCHER}/${bookingId}`,
      {},
      { responseType: "blob" }
    );
  }
  public async fetchFlightBookings(
    payload: FetchBookingsParamsRequestModel,
    isFetchNewData: boolean
  ): Promise<IMyBookingsFlight> {
    const methodName = "MyBookingsService.fetchFlightBookings";
    let mappedFlightBookings: IMyBookingsFlight = {
      count: 0,
      next: "",
      previous: "",
      bookings: [],
    };
    try {
      if (isFetchNewData) {
        await caches.delete(CACHE_NAMES.flightsBookings);
      }
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<MyBookingsResponseModel> =
        await this.restClient.get(MY_BOOKINGS_API_PATH.GET_BOOKINGS, payload);
      if (response.success && response.data) {
        const myBookingsMapper = new MyBookingsMapper();
        mappedFlightBookings = myBookingsMapper.mapFlightBookingResponse(
          response.data
        );
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedFlightBookings;
  }

  public async fetchTourBookings(
    payload: FetchBookingsParamsRequestModel
  ): Promise<IMyBookingsTour> {
    const methodName = "MyBookingsService.fetchTourBookings";
    let mappedTourBookings: IMyBookingsTour = {
      count: 0,
      next: "",
      previous: "",
      bookings: [],
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<MyBookingsResponseModel> =
        await this.restClient.get(MY_BOOKINGS_API_PATH.GET_BOOKINGS, payload);
      if (response.success && response.data) {
        const myBookingsMapper = new MyBookingsMapper();
        mappedTourBookings = myBookingsMapper.mapTourBookingResponse(
          response.data
        );
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedTourBookings;
  }

  public async fetchHotelBookings(
    params: FetchBookingsParamsRequestModel
  ): Promise<IMyBookingsHotel> {
    const methodName = "MyBookingsService.fetchHotelBookings";
    let mappedHotelBookings: IMyBookingsHotel = {
      count: 0,
      next: "",
      previous: "",
      bookings: [],
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<MyBookingsResponseModel> =
        await this.restClient.get(MY_BOOKINGS_API_PATH.GET_BOOKINGS, params);
      if (response.success && response.data) {
        const myBookingsMapper = new MyBookingsMapper();
        mappedHotelBookings = myBookingsMapper.mapHotelBookingResponse(
          response.data
        );
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedHotelBookings;
  }

  public async dowloadBrandTicket(bookingId: string) {
    const methodName = "MyBookingsService.dowloadBookingTicket";
    try {
      const response: IAGResponse = await this.restClient.get(
        formatString(MY_BOOKINGS_API_PATH.DOWNLOAD_BRAND_TICKET, bookingId),
        {},
        { responseType: "blob" }
      );
      download(response.data, "ticket.pdf");
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
  }
  public async downloadTourVoucher(bookingId: string) {
    const methodName = "MyBookingsService.downloadTourVoucher";
    try {
      const response: IAGResponse = await this.restClient.get(
        formatString(MY_BOOKINGS_API_PATH.DOWNLOAD_TOUR_VOUCHER, bookingId),
        {},
        { responseType: "blob" }
      );
      download(response.data, "voucher.pdf");
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
  }
  public async sendAirlineTicket(payload: SendAirlineTicketBodyRequest) {
    const methodName = "MyBookingsService.sendAirlineTicket";
    try {
      const response: IAGResponse = await this.restClient.post(
        MY_BOOKINGS_API_PATH.SEND_AIRLINE_TICKET,
        payload
      );

      if (response.success) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.AIRLINE_TICKETS_SENT_SUCCESS;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    notificationService.triggerNotification();
  }
}

export default MyBookingsService;
