<template>
  <div
    class="flight-search-results"
    :style="{ '--filters-toolbar-height': `${filtersToolbarHeight}px` }"
    v-if="results"
  >
    <MTransition>
      <div
        :class="[
          'search-results-filters',
          { 'm-index-level-max': breakpoint.isMobile },
        ]"
        ref="filtersToolbar"
        v-show="!breakpoint.isDesktop"
      >
        <MCard class="sr-mobile-header" v-if="breakpoint.isMobile">
          <div>Filters</div>
          <MFabButton icon="m-close" @click="showMobileFilters = false" />
        </MCard>

        <QueryCard
          :query="results.searchQuery.query"
          :is-pair-view="isPairView"
          :selected-query-leg-index="results.currentLegIndex"
          @expire:time="onTimeExpireHandler"
          ref="queryCard"
        />

        <MTransition>
          <SelectedFlightsCard
            v-if="selectedFlights.length > 0"
            :selected-flights="selectedFlights"
            @clear:flights="onClearSelectedFlightsHandler"
          />
        </MTransition>

        <template v-if="filters">
          <AppliedFiltersCard v-model="filters" />
        </template>

        <FiltersCard
          v-if="filters"
          v-model="filters"
          :min-price="results.minPrice"
          :max-price="results.maxPrice"
        />

        <div class="fs-scroll-to-top" v-if="!breakpoint.isMobile">
          <div class="fc-st-action">
            <MFabButton icon="m-upward" type="filled" @click="scrollToTop" />
          </div>
          <div class="fc-st-label">Scroll To Top</div>
        </div>
      </div>
    </MTransition>

    <MSideSheet v-model="showMobileFilters" title="Filters">
      <div class="search-results-filters" ref="filtersToolbar">
        <QueryCard
          :query="results.searchQuery.query"
          :is-pair-view="isPairView"
          :selected-query-leg-index="results.currentLegIndex"
          @expire:time="onTimeExpireHandler"
          ref="queryCard"
        />

        <MTransition>
          <SelectedFlightsCard
            v-if="selectedFlights.length > 0"
            :selected-flights="selectedFlights"
            @clear:flights="onClearSelectedFlightsHandler"
          />
        </MTransition>

        <template v-if="filters">
          <AppliedFiltersCard v-model="filters" />
        </template>

        <FiltersCard
          v-if="filters"
          v-model="filters"
          :min-price="results.minPrice"
          :max-price="results.maxPrice"
        />
      </div>
    </MSideSheet>

    <div class="search-results-container">
      <MCard class="sr-card">
        <div class="sr-title" v-if="!breakpoint.isMobile">
          {{ flightsLengthLabel }}
        </div>

        <div class="sr-actions">
          <template v-if="breakpoint.isDesktop">
            <div class="sr-action-badged">
              <MFabButton
                icon="m-sidebar"
                :type="showMobileFilters ? 'filled' : 'outlined'"
                @click="showMobileFilters = !showMobileFilters"
              />
              <div
                :class="['m-badge', 'error']"
                v-if="filters"
                :show="filters.activeFilters.length > 0"
              >
                {{ filters.activeFilters.length }}
              </div>
            </div>
          </template>

          <MSegmentedButton
            v-if="results.isReturnFlight"
            :segments="preferencesSegments"
            :disabled="selectedFlights.length > 0"
            :show-label="!breakpoint.isDesktop"
          />

          <FareCalculatorButton
            :centered="!results.isReturnFlight"
            :is-fab-button="breakpoint.isDesktop"
            @temper:fare="onApplyTemperFareHandler"
            @clear:fare="onClearFareHandler"
          />

          <template v-if="breakpoint.isTablet">
            <MFabButton
              icon="m-search"
              type="filled"
              @click="
                showChangeFlightSearchModal = !showChangeFlightSearchModal
              "
            />
          </template>
          <template v-else>
            <MButton
              class="m-icon-btn"
              @click="
                showChangeFlightSearchModal = !showChangeFlightSearchModal
              "
            >
              <MIcon name="m-search" class="btn-icon" width="20" height="20" />
              <span>Change Search</span>
            </MButton>
          </template>
        </div>

        <MTransition>
          <div class="fs-linear-loader" v-if="results.keepPolling">
            <MProgress variant="linear" indeterminate />
          </div>
        </MTransition>
      </MCard>

      <DateOptionsCard
        v-if="!results.isMultiCityFlight"
        :dates="results.availableDates"
        @select:date="onFlightDateChangeHandler"
      />

      <div class="sr-title" v-if="breakpoint.isMobile">
        {{ flightsLengthLabel }}
      </div>

      <template v-if="results.isLoading">
        <div class="fs-circular-loader" v-if="results.isLoading">
          <MProgress indeterminate />
        </div>
      </template>
      <template v-else>
        <template v-if="filteredFlights.length && !isPairView">
          <LazyLoad
            v-for="(flight, index) in filteredFlights"
            :key="index + flight.fare_options[0].pre_booking_token"
            :min-height="148"
          >
            <SingleFlightCard
              :flight="flight"
              :flights-fare-rules="results.flightsFareRules"
              :temper-fare="temperFare"
              :is-compact-view="breakpoint.isLaptop"
              @select:fare="
                onBookSingleFlightHandler(
                  $event,
                  flight.airline.logo,
                  flight.departure_time,
                  flight.arrival_time
                )
              "
              @fetch:rules="onFetchFlightFareRules"
            />
          </LazyLoad>
        </template>
        <template v-else-if="filteredPairs.length && isPairView">
          <LazyLoad
            v-for="(pair, index) in filteredPairs"
            :key="index + pair.arrival.fare_option.pre_booking_token"
            :min-height="178"
            :unrender="filteredPairs.length > 100"
          >
            <PairedFlightCard
              :pair="pair"
              :flights-fare-rules="results.flightsFareRules"
              :temper-fare="temperFare"
              :is-compact-view="breakpoint.isLaptop"
              @select:fare="onBookPairedFlightHandler"
              @fetch:rules="onFetchFlightFareRules"
            />
          </LazyLoad>
        </template>
        <template v-else>
          <div class="fc-empty">
            <MIcon
              class="fc-empty-icon"
              name="m-search"
              width="64"
              height="64"
            />

            <div class="fc-empty-title">No Results Found</div>
            <div class="fc-empty-subtitle">
              Please try adjusting your filters, changing your search dates, or
              exploring alternative routes.
            </div>
          </div>
        </template>
      </template>
    </div>

    <MDialog title="" v-model="showTimeExpireModal" hide-header>
      <template #content>
        <div class="fs-alert">
          <MIcon class="fs-alert-icon" name="m-info" width="48" height="48" />
          <div class="fs-alert-title">Search Results Expired</div>
          <div class="fs-alert-subtitle">
            To ensure you have access to the most up-to-date flight information,
            please start a new search.
          </div>
        </div>
      </template>
      <template #actions>
        <MButton class="fs-alert-action" @click="onSearchAgainHandler">
          Search Again
        </MButton>
      </template>
    </MDialog>

    <MDialog title="" v-model="showInitateBookingModal" hide-header hide-footer>
      <template #content>
        <div class="fs-booking-alert">
          <MProgress indeterminate />
          <div class="fs-booking-alert-title">
            {{ initiateBookingMessage.title }}
          </div>
          <div class="fs-booking-alert-subtitle">
            {{ initiateBookingMessage.subtitle }}
          </div>
        </div>
      </template>
    </MDialog>

    <template v-if="isMounted">
      <MDialog
        title="Change Search"
        v-model="showChangeFlightSearchModal"
        :max-width="1000"
        hide-footer
        class="fs-change-search m-scrollbar"
      >
        <template #content>
          <FlightSearch
            class="fs-change-search-card"
            @close_flight_search_dialog="onChangeSearchQueryHandler"
          />
        </template>
      </MDialog>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  MCard,
  MFabButton,
  MButton,
  MSegmentedButton,
  MIcon,
  MTransition,
  MProgress,
  MDialog,
} from "@aeroglobe/ag-core-ui";

import QueryCard from "../components/filters/QueryCard.vue";
import AppliedFiltersCard from "../components/filters/AppliedFiltersCard.vue";
import FiltersCard from "../components/filters/FiltersCard.vue";
import SelectedFlightsCard from "../components/filters/SelectedFlightsCard.vue";
import DateOptionsCard from "../components/DateOptionsCard.vue";
import SingleFlightCard from "../components/SingleFlightCard.vue";
import PairedFlightCard from "../components/PairedFlightCard.vue";
import FareCalculatorButton from "../components/FareCalculatorButton.vue";
import LazyLoad from "@/components/LazyLoad.vue";

import FlightSearch from "@/ag-flight-components/components/FlightSearch/index.vue";

import { MSegment } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import {
  AmountType,
  BreakPoint,
  DateOption,
  FareType,
  Filter,
  FilterType,
  QueryData,
  SelectedFlight,
  TemperFare,
} from "../types";
import { FlightSearchResult } from "../models/flight";
import { ROUTE_TYPE } from "@/ag-portal-common/enums/ROUTE_TYPE";
import {
  FareOption,
  FlightOption,
  ReturnFlightPair,
} from "@/ag-flight-components/types";
import { FlightFilters } from "../models/filters";
import { formatStringToRoutePath } from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import { IProfilePreferences } from "@/ag-portal-common/interfaces/profile.interface";
import UTILS from "@/ag-portal-common/utils";
import { USER_ROLES } from "@/modules/Auth/types";

export default defineComponent({
  name: "FlightSearchResult",
  components: {
    QueryCard,
    AppliedFiltersCard,
    FiltersCard,
    DateOptionsCard,
    SelectedFlightsCard,

    MCard,
    MFabButton,
    MButton,
    MSegmentedButton,
    MIcon,
    MTransition,
    MProgress,
    MDialog,
    FlightSearch,

    SingleFlightCard,
    PairedFlightCard,

    FareCalculatorButton,

    LazyLoad,
  },
  computed: {
    breakpoint(): BreakPoint {
      return {
        isDesktop: this.windowWidth <= 1280,
        isLaptop: this.windowWidth <= 1100,
        isTablet: this.windowWidth <= 786,
        isMobile: this.windowWidth <= 480,
      };
    },
    isPairView(): boolean {
      return this.preferencesSegments[1].checked;
    },
    activeFilters(): Filter[] {
      return this.filters ? this.filters.activeFilters : [];
    },
    filteredFlights(): FlightOption[] {
      return this.results ? this.results.filteredFlights : [];
    },
    filteredPairs(): ReturnFlightPair[] {
      return this.results ? this.results.filteredPairs : [];
    },
    flightsLengthLabel(): string {
      if (!this.results) {
        return "0 Flights Found";
      }

      const isPairView = this.isPairView;
      const length = isPairView
        ? this.filteredPairs.length
        : this.filteredFlights.length;
      const label = isPairView ? "Pair" : "Flight";

      return length > 0
        ? `${length} ${label}${length > 1 ? "s" : ""} Found`
        : this.results.keepPolling
        ? "Loading..."
        : `0 ${label}s Found`;
    },
    isAgentUser(): boolean {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
  },
  watch: {
    isPairView(val: boolean) {
      if (this.results) {
        this.results.isPairView = val;

        this.results.refreshFiltersData();
      }
    },
  },
  data() {
    return {
      preferencesSegments: [
        {
          label: "Single View",
          icon: "m-single-view",
          checked: true,
        },
        {
          label: "Pair View",
          icon: "m-pair-view",
          checked: false,
        },
      ] as MSegment[],

      filtersToolbarHeight: 0,
      resizeTimeout: 0,

      results: null as FlightSearchResult | null,
      filters: null as FlightFilters | null,
      selectedFlights: [] as SelectedFlight[],
      showTimeExpireModal: false,
      showChangeFlightSearchModal: false,
      showInitateBookingModal: false,

      temperFare: {
        amount: 0,
        amountType: AmountType.FIXED,
        fareType: FareType.GROSS,
      } as TemperFare,

      initiateBookingMessage: {
        title: "Initiating Your Booking",
        subtitle:
          "Please be patient while we secure your booking. This may take a moment, but rest assured, we are making sure everything is set for your journey.",
      },

      isMounted: false,

      showMobileFilters: false,
      windowWidth: window.innerWidth,
    };
  },
  created(): void {
    const { route_type, adult, child, infant, cabin_class, trips, fp } =
      this.$route.query;

    const queryData: QueryData = {
      route_type: route_type as ROUTE_TYPE,
      adult: adult as string,
      child: child as string,
      infant: infant as string,
      cabin_class: cabin_class as string,
      trips: trips as string,
      fp: fp as string,
    };

    const filters = new FlightFilters();

    const results = new FlightSearchResult(queryData, filters);

    filters.addFilter(FilterType.STOPS, "Non - Stop", false, 0);
    filters.addFilter(FilterType.STOPS, "1 Stop", false, 1);
    filters.addFilter(FilterType.STOPS, "2+ Stops", false, 2);

    filters.addFilter(FilterType.TIMES, "00:00 - 06:00", false, 0, "m-sunrise");
    filters.addFilter(FilterType.TIMES, "06:00 - 12:00", false, 0, "m-sun");
    filters.addFilter(FilterType.TIMES, "12:00 - 18:00", false, 0, "m-moon");
    filters.addFilter(FilterType.TIMES, "18:00 - 24:00", false, 0, "m-sunrise");

    this.filters = filters;
    this.results = results;

    if (this.results.isReturnFlight && this.isAgentUser) {
      const isPairView = this.onGetSavedFlightModePreferences();
      this.preferencesSegments[0].checked = !isPairView;
      this.preferencesSegments[1].checked = isPairView;
    }

    this.results.startPolling();
  },
  mounted(): void {
    this.onResizeFiltersToolbarListener();

    this.isMounted = true;

    window.addEventListener("resize", this.setWindowWidth);
  },
  unmounted(): void {
    if (this.results) {
      this.results.cancelPolling();
    }

    window.removeEventListener("resize", this.setWindowWidth);
  },
  methods: {
    setWindowWidth(): void {
      this.windowWidth = window.innerWidth;
    },
    scrollToTop(): void {
      const appBody = document.querySelector(".m-body");

      if (appBody) {
        appBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    onResizeFiltersToolbarListener(): void {
      const filtersToolbar = this.$refs.filtersToolbar as HTMLDivElement | null;

      if (filtersToolbar) {
        const observer = new ResizeObserver((entries) => {
          const entry = entries[0];

          if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
          }

          this.resizeTimeout = window.setTimeout(() => {
            this.filtersToolbarHeight = entry.target.clientHeight;
          }, 300);
        });

        observer.observe(filtersToolbar);
      }
    },
    onBookSingleFlightHandler(
      fareOption: FareOption,
      logo: string,
      departureTime: string,
      arrivalTime: string
    ): void {
      if (this.results && this.results.isNextLegAvailable) {
        this.scrollToTop();

        this.filters?.clearAirlines();

        const selectedFlight: SelectedFlight = {
          logo,
          departureTime,
          arrivalTime,
        };

        this.selectedFlights.push(selectedFlight);
        this.results.selectedFares.push(fareOption);
        this.results.getNextLeg();
      } else if (this.results) {
        this.onInitiateBookingHandler(fareOption.pre_booking_token);
      }
    },
    onBookPairedFlightHandler(fareOption: FareOption): void {
      this.onInitiateBookingHandler(fareOption.pre_booking_token);
    },
    onInitiateBookingHandler(token: string): void {
      if (this.results) {
        const { fp } = this.$route.query;
        const financialProfileId = (fp ?? "").toString();
        this.showInitateBookingModal = true;

        this.results.initiateBooking(
          [token],
          financialProfileId,
          this.onSuccessBookingHandler
        );
      }
    },
    onSuccessBookingHandler(bookingId: string | null): void {
      if (bookingId) {
        setTimeout(() => {
          this.showInitateBookingModal = false;

          this.$router.push(
            formatStringToRoutePath(PATH.FLIGHTS_BOOKING_FORM, {
              id: bookingId,
            })
          );
        }, 1000);
      } else {
        this.initiateBookingMessage = {
          title: "Initiation Failed",
          subtitle: "Booking failed to initate, please book again.",
        };

        setTimeout(() => {
          this.showInitateBookingModal = false;

          setTimeout(() => {
            this.initiateBookingMessage = {
              title: "Initating Your Booking",
              subtitle:
                "Please be patient while we secure your booking. This may take a moment, but rest assured, we are making sure everything is set for your journey.",
            };
          }, 1000);
        }, 1000);
      }
    },
    onClearSelectedFlightsHandler(): void {
      if (this.results) {
        this.selectedFlights = [];
        this.results.selectedFares = [];

        this.results.getLeg(0);
      }
    },
    onTimeExpireHandler(): void {
      this.showTimeExpireModal = true;
    },
    onSearchAgainHandler(): void {
      this.onChangeSearchQueryHandler();

      this.showTimeExpireModal = false;
    },
    onChangeSearchQueryHandler(): void {
      this.onClearSelectedFlightsHandler();
      this.onResetSegmentButtonStates();

      setTimeout(() => {
        const { route_type, adult, child, infant, cabin_class, trips, fp } =
          this.$route.query;

        const queryData: QueryData = {
          route_type: route_type as ROUTE_TYPE,
          adult: adult as string,
          child: child as string,
          infant: infant as string,
          cabin_class: cabin_class as string,
          trips: trips as string,
          fp: fp as string,
        };

        if (this.results) {
          const queryCard = this.$refs.queryCard as typeof QueryCard | null;

          if (queryCard) {
            queryCard.onStartTimerHandler();
          }

          this.results.updateSearchQuery(queryData);

          this.showChangeFlightSearchModal = false;

          this.results.startPolling();
        }
      }, 100);
    },
    onFlightDateChangeHandler(option: DateOption): void {
      if (this.results) {
        const query = this.results.searchQuery.query;
        const trips = `${query.origin},${query.destination},${
          option.departureDate
        }${option.arrivalDate ? `,${option.arrivalDate}` : ""}`;

        this.$router.push({
          query: {
            ...this.$route.query,
            trips,
          },
        });

        this.onChangeSearchQueryHandler();
      }
    },
    onFetchFlightFareRules(preBookingToken: string): void {
      if (this.results) {
        this.results.getFlightRules(preBookingToken);
      }
    },
    onResetSegmentButtonStates(): void {
      if (
        this.results &&
        this.results.isOneWayFlight &&
        this.results.isMultiCityFlight
      ) {
        this.preferencesSegments[0].checked = true;
        this.preferencesSegments[1].checked = false;
      }
    },
    onApplyTemperFareHandler(temperFare: TemperFare): void {
      this.temperFare = temperFare;
    },
    onClearFareHandler(): void {
      this.temperFare.amount = 0;
    },
    onGetSavedFlightModePreferences(): boolean {
      const savedPreferences = localStorage.getItem("user-preferences");

      if (savedPreferences) {
        const parsedPreferences: IProfilePreferences =
          JSON.parse(savedPreferences);

        return parsedPreferences.flight_search_pairs_view;
      }

      return true;
    },
  },
});
</script>

<style>
.flight-search-results {
  --filters-toolbar-height: 0px;
  --m-flight-card-border-color: #ececec;
}

.sr-action-badged .m-badge {
  position: absolute;
  top: -8px;
  right: -8px;

  font-size: 10px;
  line-height: normal;
  letter-spacing: 0.1px;
  font-weight: 500;

  padding: 4px 8px;
  border-radius: 10px;

  background-color: var(--m-error-color);
  color: white;

  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.sr-action-badged .m-badge[show="false"] {
  opacity: 0.75;
}

.sr-action-badged {
  position: relative;
}

.flight-search-results .m-card {
  padding: 16px;
}

.m-icon-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px;
}

.m-icon-btn .m-icon {
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.fs-change-search .fs-change-search-card {
  padding: 0 0 16px;
  margin: 0;
  box-shadow: none;
}

@media screen and (min-height: 960px) and (min-width: 960px) {
  .fs-change-search,
  .fs-change-search .m-dialog-content {
    overflow: visible;
  }
}

@media screen and (max-width: 1280px) {
  .search-results-filters .m-card {
    padding: 24px 0;
  }
}
</style>

<style scoped lang="css">
.flight-search-results {
  width: 100%;

  display: flex;
  gap: 20px;
  align-items: flex-start;

  color: var(--m-secondary-color);
}

.flight-search-results .search-results-filters,
.flight-search-results .search-results-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.flight-search-results .search-results-container .sr-card {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  position: relative;
}
.flight-search-results .search-results-container .sr-card .sr-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
.flight-search-results .search-results-container .sr-card .sr-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.flight-search-results .search-results-filters {
  min-width: 320px;
  max-width: 320px;

  position: sticky;
  top: calc(100vh - (var(--filters-toolbar-height) + 100% - 20px));
}
.flight-search-results .search-results-filters .sr-mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  padding: 10.5px 16px;
  border-bottom: 1px solid var(--m-border-color);
  border-radius: 0;
}

.flight-search-results .fs-scroll-to-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  font-size: 14px;
  font-weight: 500;

  margin: 20px 0;
}

.fc-st-action {
  border-radius: 50%;
  padding: 10px;
  background-color: color-mix(
    in srgb,
    var(--m-primary-color) 25%,
    var(--m-light-color) 100%
  );
}

.fs-circular-loader {
  min-height: calc(100vh - (212px + 64px));

  display: flex;
  align-items: center;
  justify-content: center;
}

.fs-linear-loader {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  padding: 0 4px;
}

.fc-empty {
  min-height: calc(100vh - (212px + 64px));
  color: var(--m-secondary-color);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
}
.fc-empty .fc-empty-icon {
  width: 64px;
  height: 64px;

  color: var(--m-primary-color);

  margin-bottom: 16px;
}
.fc-empty .fc-empty-title {
  font-size: 18px;
  font-weight: 600;

  margin-bottom: 8px;
}
.fc-empty .fc-empty-subtitle {
  font-size: 14px;
  font-weight: normal;

  color: var(--m-label-color);

  max-width: 300px;
  width: 100%;
}

.fs-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;

  padding: 16px;
}

.fs-alert .fs-alert-icon {
  width: 48px;
  height: 48px;

  color: var(--m-error-color);
}
.fs-alert .fs-alert-title {
  font-size: 24px;
  font-weight: 500;
}
.fs-alert .fs-alert-subtitle {
  color: var(--m-label-color);
}
.fs-alert-action {
  background-color: var(--m-error-color);
}

.fs-booking-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;

  padding: 16px;
}

.fs-booking-alert .fs-booking-alert-title {
  font-size: 24px;
  font-weight: 500;
}
.fs-booking-alert .fs-booking-alert-subtitle {
  color: var(--m-label-color);
  margin-bottom: 16px;
}

@media screen and (max-width: 480px) {
  .flight-search-results .search-results-container .sr-card .sr-actions {
    width: 100%;
    justify-content: space-evenly;
  }

  .flight-search-results .sr-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;

    padding-left: 12px;
  }
}
</style>
