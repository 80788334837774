import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74a99d2d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MSnapshotItemCountTile = _resolveComponent("MSnapshotItemCountTile")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!
  const _component_SnapshotDetailAccordion = _resolveComponent("SnapshotDetailAccordion")!

  return (_openBlock(), _createBlock(_component_SnapshotDetailAccordion, { title: "Payments" }, {
    overview: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentsDataAggregates, (item, index) => {
        return (_openBlock(), _createBlock(_component_ag_column, {
          xs: "12",
          sm: "12",
          md: "6",
          lg: "3",
          key: index
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MSnapshotItemCountTile, {
              title: _ctx.renderItemTitle(item),
              value: _ctx.renderItemValue(item)
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_MIcon, {
                  class: "details-type-icon icon white-icon",
                  name: "m-payment-management",
                  width: "24",
                  height: "24"
                })
              ]),
              _: 2
            }, 1032, ["title", "value"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    "accordion-content": _withCtx(() => [
      (_ctx.showDetails)
        ? (_openBlock(), _createBlock(_component_MDataTable, {
            key: 0,
            headers: _ctx.paymentHeader,
            data: _ctx.paymentsOrganizations,
            "items-per-page": 10,
            hasSearch: true
          }, {
            organization_name: _withCtx(({ item }) => [
              _createElementVNode("div", {
                onClick: ($event: any) => (_ctx.handleOrganizationRowClick($event, item)),
                class: "organization-link"
              }, [
                _createVNode(_component_MTypography, {
                  type: "label",
                  class: "description organization-link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.organization_name), 1)
                  ]),
                  _: 2
                }, 1024)
              ], 8, _hoisted_1)
            ]),
            _: 1
          }, 8, ["headers", "data"]))
        : (_openBlock(), _createBlock(_component_ResultNotFound, {
            key: 1,
            title: "No Organization Found",
            description: ""
          }))
    ]),
    _: 1
  }))
}