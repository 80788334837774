import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MSnapshotItemCountTile = _resolveComponent("MSnapshotItemCountTile")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!
  const _component_SnapshotDetailAccordion = _resolveComponent("SnapshotDetailAccordion")!

  return (_openBlock(), _createBlock(_component_SnapshotDetailAccordion, { title: "Flight Issuance" }, {
    overview: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightIssuanceAggregates, (item, index) => {
        return (_openBlock(), _createBlock(_component_ag_column, {
          xs: "12",
          sm: "12",
          md: "6",
          lg: "3",
          key: index
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MSnapshotItemCountTile, {
              title: _ctx.renderItemTitle(item),
              value: _ctx.renderItemValue(item)
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_MIcon, {
                  class: "details-type-icon icon white-icon",
                  name: "m-flight",
                  width: "24",
                  height: "24"
                })
              ]),
              _: 2
            }, 1032, ["title", "value"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    "accordion-content": _withCtx(() => [
      (_ctx.showDetails)
        ? (_openBlock(), _createBlock(_component_MDataTable, {
            key: 0,
            headers: _ctx.issuanceHeader,
            data: _ctx.flightIssuanceOrganizations,
            "item-per-page": 30,
            "has-search": true
          }, {
            organization_name: _withCtx(({ item }) => [
              _createVNode(_component_AgDiv, {
                onClick: ($event: any) => (_ctx.handleOrganizationRowClick($event, item))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MTypography, {
                    class: "description organization-link",
                    type: "label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.organization_name), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }, 8, ["headers", "data"]))
        : (_openBlock(), _createBlock(_component_ResultNotFound, {
            key: 1,
            title: "No Organization Found",
            description: ""
          }))
    ]),
    _: 1
  }))
}