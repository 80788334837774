<template>
  <MAccordion :initial-open="isInitialOpen" :external-open="isOpen">
    <template #title>
      <div class="accordion-title-container">
        <span class="accordion-title">{{ title }}</span>
      </div></template
    >
    <template #content>
      <div v-if="$slots.overview" class="overview-container">
        <MCard>
          <ag-row class="items-container">
            <slot name="overview" />
          </ag-row>
        </MCard>
      </div>
      <div v-else class="spacer"></div>
      <slot name="accordion-content" />
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MAccordion, MCard } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "SnapshotDetailAccordion",
  components: { MAccordion, MCard },
  props: {
    title: {
      type: String,
      required: true,
    },
    isInitialOpen: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style scoped>
.accordion-title {
  font-size: 20px;
  margin-right: 20px;
  font-weight: 600;
  text-align: start;
}
.overview-container {
  margin-top: 25px;
}
.overview-heading {
  font-size: 19px;
  margin: 10px;
  font-weight: bold;
}
.items-container {
  padding-top: 15px;
  border-top: solid 4px var(--green-color);
}
.spacer {
  margin-top: 20px;
}
</style>
