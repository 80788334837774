import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51fd593f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion-title-container" }
const _hoisted_2 = { class: "accordion-title" }
const _hoisted_3 = {
  key: 0,
  class: "overview-container"
}
const _hoisted_4 = {
  key: 1,
  class: "spacer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (_openBlock(), _createBlock(_component_MAccordion, {
    "initial-open": _ctx.isInitialOpen,
    "external-open": _ctx.isOpen
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    content: _withCtx(() => [
      (_ctx.$slots.overview)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_MCard, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_row, { class: "items-container" }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "overview", {}, undefined, true)
                  ]),
                  _: 3
                })
              ]),
              _: 3
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4)),
      _renderSlot(_ctx.$slots, "accordion-content", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["initial-open", "external-open"]))
}