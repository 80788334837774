import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd565f36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading" }
const _hoisted_2 = { class: "empty-items-message" }
const _hoisted_3 = { class: "empty-items-message" }
const _hoisted_4 = { class: "empty-items-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDatePicker = _resolveComponent("MDatePicker")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_ShimmerCard = _resolveComponent("ShimmerCard")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MSnapshotItemCountTile = _resolveComponent("MSnapshotItemCountTile")!
  const _component_SnapshotDetailAccordion = _resolveComponent("SnapshotDetailAccordion")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!

  return (_openBlock(), _createBlock(_component_MCard, { class: "organization-snapshot-header" }, {
    default: _withCtx(() => [
      _createVNode(_component_MCard, null, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, "Statistics " + _toDisplayString(_ctx.renderOrgName()), 1),
          _createVNode(_component_ag_row, { class: "mt-10" }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "12",
                lg: "5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MDatePicker, {
                    label: "Start Date",
                    "start-date": _ctx.defaultStartDate,
                    "max-date": new Date(),
                    onOnRangeDateChange: _cache[0] || (_cache[0] = (value) => _ctx.startDate = value),
                    placeholder: "Start Date",
                    dateType: "startDate",
                    multiCalendar: false,
                    hasRange: false,
                    autoPosition: true,
                    error: _ctx.bookingDateError
                  }, null, 8, ["start-date", "max-date", "error"])
                ]),
                _: 1
              }),
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "12",
                lg: "5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MDatePicker, {
                    label: "End Date",
                    "start-date": new Date(),
                    "max-date": new Date(),
                    "min-date": _ctx.startDate,
                    onOnRangeDateChange: _cache[1] || (_cache[1] = (value) => _ctx.endDate = value),
                    placeholder: "End Date",
                    dateType: "endDate",
                    multiCalendar: false,
                    hasRange: false,
                    autoPosition: true,
                    error: _ctx.bookingDateError
                  }, null, 8, ["start-date", "max-date", "min-date", "error"])
                ]),
                _: 1
              }),
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "12",
                lg: "2",
                class: "d-flex align-items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MButton, {
                    onClick: _ctx.handleFetchData,
                    disabled: _ctx.isLoading,
                    class: "margin_bottom_20"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Fetch Statistics")
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.isOrganizationProductivityLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "150px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "100px" })
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.showDetails)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Overall Counts" }, {
                    overview: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Total Flight Bookings",
                            value: 
                  _ctx.organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_flight_bookings
                
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-issue-ticket",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Total Issued Tickets",
                            value: 
                  _ctx.organizationProductivity?.total_tickets_issued
                    ?.total_issued_tickets
                
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-issue-ticket",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Total Hotel Bookings",
                            value: 
                  _ctx.organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_hotels_bookings
                
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-hotel",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Total Issued Hotels",
                            value: 
                  _ctx.organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_hotels_issued
                
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-hotel",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Total Payment Created",
                            value: 
                  _ctx.organizationProductivity?.payment_details?.aggregates
                    .total_payments_created
                
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-payment-management",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Total Credit Limit Requests",
                            value: _ctx.organizationProductivity?.total_credit_limit_requests
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-credit-limit-management",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Total Sabre Segments",
                            value: _ctx.organizationProductivity?.total_sabre_segments
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-sabre-segment",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Booked" }, {
                    overview: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationProductivity
                ?.hotel_and_flight_details?.flight_bookings, (item, index) => {
                        return (_openBlock(), _createBlock(_component_ag_column, {
                          key: index,
                          xs: "12",
                          sm: "12",
                          md: "6",
                          lg: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_MSnapshotItemCountTile, {
                              title: _ctx.replaceUnderscoreWithSpace(item.provider_name),
                              value: item.count
                            }, {
                              icon: _withCtx(() => [
                                _createVNode(_component_MIcon, {
                                  class: "details-type-icon icon white-icon",
                                  name: "m-flight",
                                  width: "24",
                                  height: "24"
                                })
                              ]),
                              _: 2
                            }, 1032, ["title", "value"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _withDirectives(_createElementVNode("p", _hoisted_2, " Nothing Booked Yet ", 512), [
                        [_vShow, _ctx.showBookedEmptyMsg]
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Issued" }, {
                    overview: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationProductivity
                ?.total_tickets_issued?.issued_tickes, (item, index) => {
                        return (_openBlock(), _createBlock(_component_ag_column, {
                          key: index,
                          xs: "12",
                          sm: "12",
                          md: "6",
                          lg: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_MSnapshotItemCountTile, {
                              title: _ctx.replaceUnderscoreWithSpace(item.provider_name),
                              value: item.count
                            }, {
                              icon: _withCtx(() => [
                                _createVNode(_component_MIcon, {
                                  class: "details-type-icon icon white-icon",
                                  name: "m-flight",
                                  width: "24",
                                  height: "24"
                                })
                              ]),
                              _: 2
                            }, 1032, ["title", "value"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _withDirectives(_createElementVNode("p", _hoisted_3, " Nothing Issued Yet ", 512), [
                        [_vShow, _ctx.showIssuedEmptyMsg]
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Payments" }, {
                    overview: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationProductivity?.payment_details
                ?.payments, (item, index) => {
                        return (_openBlock(), _createBlock(_component_ag_column, {
                          key: index,
                          xs: "12",
                          sm: "12",
                          md: "6",
                          lg: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_MSnapshotItemCountTile, {
                              title: _ctx.replaceUnderscoreWithSpace(item.payment_method),
                              value: item.count
                            }, {
                              icon: _withCtx(() => [
                                _createVNode(_component_MIcon, {
                                  class: "details-type-icon icon white-icon",
                                  name: "m-payment-management",
                                  width: "24",
                                  height: "24"
                                })
                              ]),
                              _: 2
                            }, 1032, ["title", "value"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _withDirectives(_createElementVNode("p", _hoisted_4, " No Payment Found ", 512), [
                        [_vShow, _ctx.showPaymentsEmptyMsg]
                      ])
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.showResultNotFound)
              ? (_openBlock(), _createBlock(_component_ResultNotFound, { key: 1 }))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    _: 1
  }))
}