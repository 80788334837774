<template>
  <MCard class="sector-snapshot-header">
    <div class="field-container">
      <MCombobox
        v-model:inputValue="sector"
        label="Select Sector"
        itemValue="value"
        itemLabel="label"
        :options="sectors"
      />
    </div>
    <template v-if="$store.getters.isSectorStatsLoading">
      <ShimmerCard card-height="200px" />
      <ShimmerCard card-height="200px" />
      <ShimmerCard card-height="150px" />
      <ShimmerCard card-height="100px" />
    </template>
    <template v-else>
      <template v-if="showDetails">
        <!-- General Stats Card -->
        <SnapshotDetailAccordion title="General Stats">
          <template #overview>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Enabled Websites"
                :value="sectorStats?.aggregates?.enabled_website_count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-website-management"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Disabled Websites"
                :value="sectorStats?.aggregates?.disabled_website_count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-website-management"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Logo Added"
                :value="sectorStats?.aggregates?.logo_added_count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-logo-icon"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Color Added"
                :value="sectorStats?.aggregates?.color_added_count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-color-icon"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Default Color"
                :value="sectorStats?.aggregates?.default_color_count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-default-color-fill"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Default Logo"
                :value="sectorStats?.aggregates?.default_logo_count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-default-logo-icon"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
          </template>
        </SnapshotDetailAccordion>

        <!-- Users Card -->
        <SnapshotDetailAccordion title="Users">
          <template #overview>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Owners"
                :value="sectorStats?.users?.owners"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-my-team"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Employees"
                :value="sectorStats?.users?.employees"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-my-team"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Sub Agents"
                :value="sectorStats?.users?.sub_agents"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-my-team"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
          </template>
        </SnapshotDetailAccordion>

        <!-- Organization Card -->
        <SnapshotDetailAccordion title="Organizations">
          <template #accordion-content>
            <MDataTable
              v-if="showOrganizationTable"
              :headers="headers"
              :data="sectorOrganizations"
              :items-per-page="10"
              :simpleDataTable="true"
            >
              <template #logo="{ item }">
                <div><img width="60" :src="item.logo" /></div>
              </template>
              <template #org_color="{ item }">
                <div>
                  <ag-color-chip
                    :color-name="item.org_color"
                    :color-code="item.org_color"
                  />
                </div>
              </template>
              <template #name="{ item }">
                <div @click="handleRowClick($event, item)">
                  <MTypography
                    class="description organization-link"
                    type="label"
                  >
                    {{ item.name }}
                  </MTypography>
                </div>
              </template>
              <template #domain_name="{ item }">
                <MTypography
                  class="description td-full-width"
                  type="label"
                  v-if="item.domain_name"
                >
                  {{ item.domain_name }}
                </MTypography>
                <MTypography
                  class="description td-full-width"
                  type="label"
                  v-else
                >
                  N/A
                </MTypography>
              </template>
              <template #display_name="{ item }">
                <MTypography
                  class="description td-full-width"
                  type="label"
                  v-if="item.display_name"
                >
                  {{ item.display_name }}
                </MTypography>
                <MTypography
                  class="description td-full-width"
                  type="label"
                  v-else
                >
                  N/A
                </MTypography>
              </template>
            </MDataTable>
            <ResultNotFound v-else test-id="" title="Organizations not found" />
          </template>
        </SnapshotDetailAccordion>
      </template>
      <ResultNotFound
        v-if="showResultNotFound"
        test-id=""
        title="Sector Details Not Found"
      />
    </template>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import { ISector } from "@/ag-portal-common/interfaces/sector.interface";
import { ISectorStatistics } from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { MDataTable, MCard, MCombobox } from "@aeroglobe/ag-core-ui";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import ShimmerCard from "@/components/ShimmerCard.vue";
import SnapshotDetailAccordion from "@/components/SnapshotDetailAccordion.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";
import MSnapshotItemCountTile from "../MSnapshotItemCountTile.vue";

export default defineComponent({
  name: "OrganizationSnapshotAnalyticsTab",
  components: {
    MDataTable,
    MCard,
    MCombobox,
    ShimmerCard,
    SnapshotDetailAccordion,
    ResultNotFound,
    MSnapshotItemCountTile,
  },
  data(): {
    sector: string;
    headers: MDataTableHeader[];
    lastSelectedSector: string;
    isInitial: boolean;
  } {
    return {
      sector: "",
      isInitial: true,
      headers: [
        {
          title: "Organization Name",
          value: "name",
          key: "name",
          sortable: true,
          contentAlign: "left",
          align: "left",
        },
        {
          title: "Owners",
          value: "owner_count",
          key: "owner_count",
          sortable: true,
        },
        {
          title: "Sub Agents",
          value: "sub_agent_count",
          key: "sub_agent_count",
          sortable: true,
        },
        {
          title: "Employees",
          value: "employee_count",
          key: "employee_count",
          sortable: true,
        },
        {
          title: "Logo",
          value: "logo",
          key: "logo",
          sortable: false,
        },
        {
          title: "Display Name",
          value: "display_name",
          key: "display_name",
          sortable: true,
        },
        {
          title: "Website Slugs",
          value: "website_slug",
          key: "website_slug",
          sortable: true,
        },
        {
          title: "Website Status",
          value: "website_status",
          key: "website_status",
          sortable: true,
        },
        {
          title: "Domain Name",
          value: "domain_name",
          key: "domain_name",
          sortable: true,
        },
        {
          title: "Organization Color",
          value: "org_color",
          key: "org_color",
          sortable: false,
        },
      ],
      lastSelectedSector: "",
    };
  },
  methods: {
    fetchSectorStats() {
      const { sector_id } = this.$route.query;
      this.isInitial = false;
      this.$store.dispatch("getSectorStats", sector_id);
    },
    handleRowClick(e: any, row: any) {
      const org_id = row?.public_id;
      const url = this.$router.resolve({
        path: PATH.ORGANIZATION_SNAPSHOT,
        query: { org_id: org_id },
      });

      // Open the route in a new tab
      window.open(url.href, "_blank");
    },
  },
  computed: {
    sectors(): IObject[] {
      return (
        this.$store.getters.sectors?.map((item: ISector) => {
          return {
            label: item.sector_name,
            value: item.id.toString(),
          };
        }) || []
      );
    },
    sectorStats(): ISectorStatistics {
      return this.$store.getters.sectorStats;
    },
    sectorOrganizations(): any[] {
      return this.$store.getters.sectorStats.organizations;
    },
    showDetails(): boolean {
      const isNotEmpty = !_.isEmpty(this.sectorStats);
      return isNotEmpty;
    },
    showOrganizationTable(): boolean {
      const isLoading = this.$store.getters.isSalesLoading;
      const organizations = this.$store.getters.sectorStats.organizations || [];
      return !isLoading && organizations.length > 0;
    },
    showResultNotFound(): boolean {
      return (
        !this.isInitial &&
        !this.$store.getters.isSectorStatsLoading &&
        !this.showDetails
      );
    },
  },
  watch: {
    "$route.query": {
      handler: function (value) {
        const isNotEmpty = !_.isEmpty(value);
        if (isNotEmpty) {
          if (this.lastSelectedSector !== value?.sector_id) {
            this.fetchSectorStats();
            this.sector = value?.sector_id;
            this.lastSelectedSector = value.sector_id;
          }
        }
      },
      immediate: true,
    },
    sector: {
      handler: function (sector: string) {
        if (sector) {
          const path = `${PATH.SECTOR_SNAPSHOT}${formatQueryPath({
            sector_id: sector,
          })}`;
          this.$router.push(path);
        }
      },
    },
  },
  mounted() {
    const { sector_id } = this.$route.query;
    if (sector_id) {
      this.sector = sector_id as string;
    }
  },
});
</script>

<style lang="scss" scoped>
.sector-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    img {
      width: 70px;
    }
    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }
      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.autocomplete-container {
  width: 100%;
}

.heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-left: solid 8px var(--green-color);
}
.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;
  &:hover {
    color: #0c47bc;
  }
}

.organization-link {
  color: var(--green-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.name-column {
  min-width: 210px;
}
.field-container {
  margin-bottom: 50px;
  max-width: 640px;
}
.white-icon {
  color: #fff;
}
</style>
